import { Badge, BadgeType, H4, ListItem, SimpleList } from '@appliedsystems/applied-design-system';
import { TransactionType } from '@appliedsystems/payments-core';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { toIntlFormatHelper } from '../../util/getAmountWithFees';
import { PayBySelection } from '../HostedPaymentPageContainer/enums';
import { AvailableTransactionTypes, FullInvoiceGroup } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './SummaryCard.module.scss';

type InvoiceSummaryProps = {
  invoice: FullInvoiceGroup;
  isFinanceable: (invoice: FullInvoiceGroup) => boolean | undefined;
};

type PaymentAmountDetailType = {
  fee: string;
  subtotal: string;
  total: string;
};

type PaymentDescriptionProps = {
  paymentOption: AvailableTransactionTypes | undefined;
  downPaymentAmount: number;
  paymentDescription: string;
  paymentAmountDetail: PaymentAmountDetailType;
};

const DescriptionItem = ({ title, value }: { title: string; value: string | number }) => (
  <div className={classes.description}>
    <div>{title}</div>
    <div>{value || '-'}</div>
  </div>
);

const InvoiceSummary = ({ invoice, isFinanceable }: InvoiceSummaryProps) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const { hppData } = useHppDataStore();
  const { pf } = hppData;
  const badgeType = isFinanceable(invoice) ? BadgeType.Accordion : BadgeType.Applied;
  const badgeText = isFinanceable(invoice) ? t('FINANCING_AVAILABLE') : t('DUE_IN_FULL');
  const badgeIcon = isFinanceable(invoice) ? undefined : 'NoIcon';
  const badgeTestId = isFinanceable(invoice) ? undefined : 'due-in-full-badge';
  return (
    <div key={invoice.invoiceNumber}>
      <div className={classes.invoice}>
        <span className={classes.invoiceTitle}>
          {t('INVOICE')} {invoice.invoiceNumber}{' '}
          {pf?.quote?.quoteId && (
            <Badge type={badgeType} size="xxs" icon={badgeIcon} testId={badgeTestId}>
              {badgeText}
            </Badge>
          )}
        </span>
      </div>
      {invoice.invoiceItems.map((item) => (
        <div className={classes.invoiceItem}>
          <div>{item.description}</div>
          <div>{toIntlFormatHelper(item.amountDue, currencyMap[locale], locale)}</div>
        </div>
      ))}
    </div>
  );
};

export const SummaryCard = ({ isConfirmation }: { isConfirmation: boolean }) => {
  const { t } = usePaymentsTranslation();
  const { hppData, selectedInvoices } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const { paymentFee, paymentAmount, payBy, paymentDescription, paymentMethod, paymentOption, pf } = hppData;
  const downPaymentAmount = pf?.quote?.quoteId ? pf.quote.downPaymentAmount : 0;
  const { pathname } = useLocation();
  const isCheckout = pathname.includes('checkoutv2');

  const paymentAmountDetail = useMemo(() => {
    const currencyCode = currencyMap[locale];
    return {
      fee: toIntlFormatHelper(paymentFee, currencyCode, locale),
      subtotal: toIntlFormatHelper(paymentAmount, currencyCode, locale),
      total: toIntlFormatHelper(hppData.paymentTotal, currencyCode, locale),
    };
  }, [paymentAmount, paymentFee, hppData.paymentTotal, locale]);

  const isFinanceable = useCallback(
    (invoice: FullInvoiceGroup) => {
      return pf?.quickQuote?.financeableInvoices.some(
        (financeableInvoice) => financeableInvoice.invoiceNumber === invoice.invoiceNumber,
      );
    },
    [pf?.quickQuote?.financeableInvoices],
  );

  const getTotalDueNow = useCallback(() => {
    let total = downPaymentAmount;
    selectedInvoices.forEach((invoice) => {
      if (!isFinanceable(invoice)) {
        total += invoice.amountDueMinorUnits;
      }
    });
    return total;
  }, [selectedInvoices, isFinanceable, downPaymentAmount]);

  const renderInvoiceSummary = useMemo(() => {
    return selectedInvoices.length ? (
      selectedInvoices.map((invoice) => (
        <InvoiceSummary key={invoice.invoiceNumber} invoice={invoice} isFinanceable={isFinanceable} />
      ))
    ) : (
      <div className={classes.description}>
        <div>{t('DESCRIPTION')}</div>
        <div>-</div>
      </div>
    );
  }, [selectedInvoices, t, isFinanceable]);

  const renderInvoiceTotalsSummary = useMemo(() => {
    if (paymentOption === TransactionType.PREMIUM_FINANCE && pf?.quote?.quoteId) {
      return (
        <>
          <div>
            <div className={classes.invoice}>
              <span>{t('DOWN_PAYMENT')}</span>
              <div>{toIntlFormatHelper(downPaymentAmount, currencyMap[locale], locale)}</div>
            </div>
            {selectedInvoices.length &&
              selectedInvoices.map((invoice) => {
                if (!isFinanceable(invoice)) {
                  return (
                    <>
                      <div className={classes.invoice}>
                        <span>
                          {t('INVOICE')} {invoice.invoiceNumber}
                        </span>
                        <div>{invoice.amountDue}</div>
                      </div>
                      {invoice.invoiceItems.map((item) => (
                        <div className={classes.invoiceItem}>
                          <div>{item.description}</div>
                        </div>
                      ))}
                    </>
                  );
                } else {
                  return (
                    <>
                      {invoice.invoiceItems.map((item) => (
                        <div className={classes.invoiceItem}>
                          <div>{item.description}</div>
                        </div>
                      ))}
                    </>
                  );
                }
              })}
          </div>
        </>
      );
    }
    return (
      <>
        {selectedInvoices.length ? (
          selectedInvoices.map((invoice) => {
            return (
              <div key={invoice.invoiceNumber}>
                <div className={classes.invoice}>
                  <span>
                    {t('INVOICE')} {invoice.invoiceNumber}
                  </span>
                  <div>{invoice.amountDue}</div>
                </div>
                {invoice.invoiceItems.map((item) => (
                  <div className={classes.invoiceItem}>
                    <div>{item.description}</div>
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className={classes.description}>
            <div>{t('DESCRIPTION')}</div>
            <div>-</div>
          </div>
        )}
      </>
    );
  }, [paymentOption, pf?.quote?.quoteId, selectedInvoices, t, downPaymentAmount, locale, isFinanceable]);

  const paymentTotal = useMemo(() => {
    const totalPaid = paymentMethod ? paymentAmountDetail.total : '-';
    const totalDueNow = toIntlFormatHelper(getTotalDueNow(), currencyMap[locale], locale);
    return (
      <ListItem>
        <div className={classes.listItem}>
          <H4>{t(isConfirmation ? 'TOTAL_PAID' : 'TOTAL_DUE_NOW')}</H4>
          <H4>{paymentOption === TransactionType.PAID_IN_FULL ? totalPaid : totalDueNow}</H4>
        </div>
        {isConfirmation && (
          <div className={classes.listItemSubheading}>
            <span>{t('PAYMENT_METHOD')}</span>
            <span className={classes.paymentMethod}>
              {paymentMethod}{' '}
              {hppData.endDigits &&
                t('ENDING_WITH', undefined, {
                  lastFourDigits: hppData.endDigits,
                } as any)}
            </span>
          </div>
        )}
      </ListItem>
    );
  }, [
    t,
    isConfirmation,
    paymentOption,
    paymentAmountDetail.total,
    getTotalDueNow,
    locale,
    paymentMethod,
    hppData.endDigits,
  ]);

  const InvoiceItems = ({ selectedInvoices }: { selectedInvoices: FullInvoiceGroup[] }) => {
    return (
      <div>
        {selectedInvoices.map((invoice) => {
          if (isFinanceable(invoice)) {
            return (
              <>
                {invoice.invoiceItems.map((item) => (
                  <div className={classes.invoiceItem}>
                    <div>{item.description}</div>
                  </div>
                ))}
              </>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  const PaymentDescription = ({
    paymentOption,
    downPaymentAmount,
    paymentDescription,
    paymentAmountDetail,
  }: PaymentDescriptionProps) => {
    const { locale } = Locale.useContainer();
    if (paymentOption === TransactionType.PREMIUM_FINANCE && pf?.quote?.quoteId) {
      return (
        <>
          <div>{t('DOWN_PAYMENT') || t('DESCRIPTION')}</div>
          <div>{toIntlFormatHelper(downPaymentAmount, currencyMap[locale], locale) || '-'}</div>
        </>
      );
    } else {
      return (
        <>
          <div>{paymentDescription || t('DESCRIPTION')}</div>
          <div>{paymentAmountDetail.subtotal || '-'}</div>
        </>
      );
    }
  };

  const getFeeDisplayValue = () => {
    if (hppData.paymentMethod) {
      return paymentAmountDetail.fee;
    } else if (pf?.quote?.quoteId) {
      return 'TBD';
    } else {
      return '-';
    }
  };

  const hideDescription = isCheckout && !paymentDescription;
  const isPremiumFinanceSelected = pf?.quote?.quoteId && paymentOption === TransactionType.PREMIUM_FINANCE;
  const shouldDisplayDescription = payBy !== PayBySelection.INVOICE && !hideDescription;

  return (
    <article className={classes.summaryCard}>
      <SimpleList title={t(isConfirmation ? 'PAYMENT_SUMMARY' : 'ORDER_SUMMARY')}>
        <hr className={classes.line} />
        {payBy === PayBySelection.INVOICE && <ListItem>{renderInvoiceSummary}</ListItem>}
        {shouldDisplayDescription && (
          <ListItem>
            <DescriptionItem title={paymentDescription || t('DESCRIPTION')} value={paymentAmountDetail.subtotal} />
          </ListItem>
        )}
        <ListItem>
          <DescriptionItem title={t('SUBTOTAL')} value={paymentAmountDetail.subtotal} />
          {isConfirmation && (
            <div className={classes.listItem}>
              <span>{t('CONVENIENCE_FEE')}</span>
              <span>{paymentAmountDetail.fee}</span>
            </div>
          )}
        </ListItem>
        {isConfirmation && paymentTotal}
      </SimpleList>
      {!isConfirmation && (
        <SimpleList title={t('PAYMENT_SUMMARY')}>
          <hr className={classes.line} />
          <ListItem>
            {shouldDisplayDescription && (
              <div className={classes.description}>
                <PaymentDescription
                  paymentOption={paymentOption}
                  downPaymentAmount={downPaymentAmount}
                  paymentDescription={paymentDescription}
                  paymentAmountDetail={paymentAmountDetail}
                />
              </div>
            )}
            {payBy === PayBySelection.INVOICE && renderInvoiceTotalsSummary}
            <div className={classes.listItem}>
              <span>{t('CONVENIENCE_FEE')}</span>
              <span>{getFeeDisplayValue()}</span>
            </div>
          </ListItem>
          <span>{paymentTotal}</span>
          {isPremiumFinanceSelected && pf.quote && (
            <>
              <div className={classes.invoice}>
                <span className={classes.invoiceTitle}>{t('AMOUNT_FINANCED')}</span>
                <span>{toIntlFormatHelper(pf.quote.amountFinanced, currencyMap[locale], locale)}</span>
              </div>
              <InvoiceItems selectedInvoices={selectedInvoices} />
            </>
          )}
        </SimpleList>
      )}
    </article>
  );
};
