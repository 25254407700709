import { Col, DataGrid, DataGridColumnType, Flex, Label, Row } from '@appliedsystems/applied-design-system';
import { CreateQuoteSuccessResponse, LocaleCode, PaymentSchedule, toIntlFormat } from '@appliedsystems/payments-core';
import React, { useMemo } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { toLocaleDateString } from '../../util/date';
import { PfInfo } from '../HostedPaymentPageContainer/types';

interface PremiumFinanceQuoteInformationProps {
  locale: LocaleCode;
  pfInfo: PfInfo;
}

export const PremiumFinanceQuoteInformation = ({
  locale,
  pfInfo,
}: PremiumFinanceQuoteInformationProps): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const columns = useMemo<DataGridColumnType[]>(() => {
    return [
      {
        name: 'amountFinanced',
        title: t('AMOUNT_FINANCED'),
        isSortable: false,
        cellRenderer: (row: CreateQuoteSuccessResponse) => (
          <span>
            {toIntlFormat(
              {
                amount: row.amountFinanced,
                currencyCode: row.currency,
              },
              locale,
            )}
          </span>
        ),
      },
      {
        name: 'downPayment',
        title: t('DOWN_PAYMENT'),
        isSortable: false,
        cellRenderer: (row: CreateQuoteSuccessResponse) => {
          return (
            <Flex className="flex-no-wrap flex-valign-center gap-h-50">
              <span>
                {toIntlFormat(
                  {
                    amount: row.downPaymentAmount,
                    currencyCode: row.currency,
                  },
                  locale,
                )}
              </span>
            </Flex>
          );
        },
      },
      {
        name: 'installmentAmount',
        title: t(
          pfInfo.quote?.installmentFrequency === PaymentSchedule.QUARTERLY ? 'QUARTERLY_AMOUNT' : 'MONTHLY_AMOUNT',
        ),
        isSortable: false,
        cellRenderer: (row: CreateQuoteSuccessResponse) => (
          <span>
            {toIntlFormat(
              {
                amount: row.installmentAmount,
                currencyCode: row.currency,
              },
              locale,
            )}
          </span>
        ),
      },
      {
        name: 'numberOfPayments',
        title: t('NUMBER_OF_PAYMENTS'),
        isSortable: false,
        cellRenderer: (row: CreateQuoteSuccessResponse) => <span>{row.numberOfInstallments}</span>,
      },
      {
        name: 'nextPaymentDue',
        title: t('NEXT_PAYMENT_DUE'),
        isSortable: false,
        cellRenderer: (row: CreateQuoteSuccessResponse) => <span>{toLocaleDateString(row.firstDueDate, locale)}</span>,
      },
    ];
  }, [locale, pfInfo.quote?.installmentFrequency, t]);

  return (
    <>
      {pfInfo.quote?.quoteId && (
        <Row className="mb-100">
          <Col xs={12}>
            <div>
              <Flex className="mb-sm-200">
                <div>
                  <div>
                    <Label>{t('FINANCE_COMPANY')}</Label>
                  </div>
                  <div>{pfInfo.quote.company}</div>
                </div>
                <div>
                  <div>
                    <Label>{t('FINANCE_QUOTE_NUMBER')}</Label>
                  </div>
                  <div>{pfInfo.quote.quoteId}</div>
                </div>
              </Flex>
              <DataGrid
                columns={columns}
                rows={[pfInfo.quote]}
                rowIdField="id"
                testId="premium-finance-details"
                hideFooter
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
