import { Col, Flex } from '@appliedsystems/applied-design-system';
import { PaymentMethod, TransactionType } from '@appliedsystems/payments-core';
import React from 'react';
import achIcon from '../../assets/icons/ach.svg';
import creditCardIcon from '../../assets/icons/credit-card.svg';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { getAmountWithFees } from '../../util/getAmountWithFees';
import { AvailablePaymentMethod, HppData } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PaymentOptionCard } from '../PaymentOptionCard/PaymentOptionCard';
import { PaymentOptionRadioButton } from '../PaymentOptionRadioButton/PaymentOptionRadioButton';
import classes from './PaymentMethodSelection.module.scss';

export type PaymentMethodDetails = {
  [key in AvailablePaymentMethod]: {
    icon: string;
    title: string;
    tooltip?: string;
  };
};

type Props = {
  isDownPayment?: boolean;
  onPaymentMethodSelected: (paymentDetails: Pick<HppData, 'paymentMethod' | 'paymentFee'>) => void;
};

export const availablePaymentMethods: AvailablePaymentMethod[] = [PaymentMethod.Card, PaymentMethod.Ach];

export const PaymentMethodSelection = ({ isDownPayment, onPaymentMethodSelected }: Props): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { hppData, paymentMethodConfig } = useHppDataStore();
  const { locale } = Locale.useContainer();

  const paymentMethods: PaymentMethodDetails = {
    [PaymentMethod.Ach]: {
      icon: achIcon,
      title: t('ACH_DIRECT_DEBIT'),
      tooltip: t('ACH_DIRECT_DEBIT_TOOLTIP'),
    },
    [PaymentMethod.Card]: {
      icon: creditCardIcon,
      title: t('CREDIT_CARD'),
    },
  };

  if (hppData.pf?.quote?.quoteId && !isDownPayment) {
    return (
      <PaymentOptionRadioButton
        availablePaymentMethods={availablePaymentMethods}
        paymentMethods={paymentMethods}
        onPaymentMethodSelected={onPaymentMethodSelected}
      />
    );
  }

  return (
    <Flex className={classes.container}>
      {availablePaymentMethods.map((paymentMethodKey) => {
        const paymentConfig = paymentMethodConfig[paymentMethodKey];

        if (!paymentConfig.allowed) return <Col key={paymentMethodKey}></Col>; // Not an allowed payment method

        const { icon, title, tooltip } = paymentMethods[paymentMethodKey];
        const downPayment = hppData.pf?.quote?.downPaymentAmount || 0;
        const amount = hppData.paymentOption === TransactionType.PREMIUM_FINANCE ? downPayment : hppData.paymentAmount;

        const { paymentTotal, paymentFee } = getAmountWithFees(
          paymentMethodKey,
          amount,
          paymentConfig.fee,
          currencyMap[locale],
        );

        return (
          <PaymentOptionCard
            key={paymentMethodKey}
            icon={icon}
            title={title}
            subtotal={amount}
            total={paymentTotal}
            fee={paymentFee}
            showFeeAsPercentage={[PaymentMethod.Card].includes(paymentMethodKey)}
            isActiveCard={hppData.paymentMethod === paymentMethodKey}
            tooltip={tooltip}
            choosePaymentMethod={() => {
              if (hppData.paymentMethod !== paymentMethodKey) {
                onPaymentMethodSelected({
                  paymentMethod: paymentMethodKey,
                  paymentFee: paymentConfig.fee,
                });
              }
            }}
          />
        );
      })}
    </Flex>
  );
};
