import { Badge, BadgeShape, BadgeType, H4, ListItem, SimpleList } from '@appliedsystems/applied-design-system';
import React, { useMemo } from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { toIntlFormatHelper } from '../../util/getAmountWithFees';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './PremiumFinanceSummaryCard.module.scss';

// update so props are being
export const PremiumFinanceSummaryCard = () => {
  const { t } = usePaymentsTranslation();
  const { hppData } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const { paymentFee, paymentAmount, paymentMethod } = hppData;

  const paymentAmountDetail = useMemo(() => {
    const currencyCode = currencyMap[locale];
    return {
      fee: toIntlFormatHelper(paymentFee, currencyCode, locale),
      subtotal: toIntlFormatHelper(paymentAmount, currencyCode, locale),
      total: toIntlFormatHelper(hppData.paymentTotal, currencyCode, locale),
    };
  }, [paymentAmount, paymentFee, hppData.paymentTotal, locale]);

  if (!hppData.pf?.quote?.quoteId) {
    console.error('missing quote data');
    return <></>;
  }
  // fixing the hardcoded business auto will come in later ticket
  return (
    <article className={classes.summaryCard}>
      <SimpleList title={t('PAYMENT_SUMMARY')}>
        <hr className={classes.line} />
        <ListItem>
          <div>
            <div className={classes.description}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.downPayment}>{t('DOWN_PAYMENT')}</div>
                <Badge shape={BadgeShape.Pill} type={BadgeType.Success} size="xxs" icon={'NoIcon'}>
                  {'PAID'}
                </Badge>
              </div>
              <div>{toIntlFormatHelper(hppData.pf.quote.downPaymentAmount, currencyMap[locale], locale) || '-'}</div>
            </div>
            <div className={classes.amountFinanced}>Business auto</div>
            <div className={classes.description}>
              <div>{t('CONVENIENCE_FEE')}</div>
              <span>{paymentAmountDetail.fee}</span>
            </div>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.listItem}>
            <H4>{t('TOTAL_PAID_TODAY')}</H4>
            <H4>
              {toIntlFormatHelper(hppData.paymentFee + hppData.pf.quote.downPaymentAmount, currencyMap[locale], locale)}
            </H4>
          </div>
          <div className={classes.description}>
            <div>{t('AMOUNT_FINANCED')}</div>
            <span>{toIntlFormatHelper(hppData.pf.quote.amountFinanced, currencyMap[locale], locale)}</span>
          </div>
          <div className={classes.amountFinanced}>Business auto</div>
          <div className={classes.description}>
            <div>{t('DOWN_PAYMENT_METHOD')}</div>
            <span className={classes.paymentMethod}>
              {paymentMethod}{' '}
              {hppData.endDigits &&
                t('ENDING_WITH', undefined, {
                  lastFourDigits: hppData.endDigits,
                } as any)}
            </span>
          </div>
        </ListItem>
      </SimpleList>
    </article>
  );
};
