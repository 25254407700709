import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  SectionTypes,
  SectionV2,
  TextField,
  TextFieldFormats,
} from '@appliedsystems/applied-design-system';
import React from 'react';
import * as yup from 'yup';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { toIntlFormatHelper } from '../../util/getAmountWithFees';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './SetUpPaymentInstallmentsModal.module.scss';

type Props = {
  isSetUpPaymentInstallmentsModalOpen: boolean;
  onClose: () => void;
};

export const SetUpPaymentInstallmentsModal = ({
  isSetUpPaymentInstallmentsModalOpen,
  onClose,
}: Props): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { hppData, setHppData } = useHppDataStore();
  const { locale } = Locale.useContainer();

  const pfQuote = hppData.pf?.quote?.quoteId ? hppData.pf.quote : undefined;

  const handleAccountInformationSubmit = (values: AccountInformationSchemaType) => {
    setHppData({
      pf: hppData.pf?.quote?.quoteId
        ? {
            ...hppData.pf,
            accountInformation: {
              accountHolderName: values.accountHolderName,
              accountNumber: values.accountNumber,
              abaRoutingNumber: values.abaRoutingNumber,
              authorizePaymentInstallments: values.authorizePaymentInstallments,
            },
          }
        : undefined,
    });
    onClose();
  };

  const makeSchema = () => {
    const { accountHolderName, accountNumber, abaRoutingNumber, authorizePaymentInstallments } =
      hppData.pf?.accountInformation || {};

    return yup.object({
      accountHolderName: yup.string().label(t('ACCOUNT_HOLDER_NAME')).required().default(accountHolderName),
      accountNumber: yup.string().label(t('ACCOUNT_NUMBER')).required().default(accountNumber),
      confirmAccountNumber: yup
        .string()
        .label(t('ACCOUNT_NUMBER'))
        .required()
        .test({
          test: (_, context) => context.parent.accountNumber === context.parent.confirmAccountNumber,
          message: t('ACCOUNT_NUMBER_MISMATCH'),
        })
        .default(accountNumber),
      abaRoutingNumber: yup.string().label(t('ABA_ROUTING_NUMBER')).required().default(abaRoutingNumber),
      confirmAbaRoutingNumber: yup
        .string()
        .label(t('ABA_ROUTING_NUMBER'))
        .required()
        .test({
          test: (_, context) => context.parent.abaRoutingNumber === context.parent.confirmAbaRoutingNumber,
          message: t('ABA_ROUTING_NUMBER_MISMATCH'),
        })
        .default(abaRoutingNumber),
      authorizePaymentInstallments: yup
        .boolean()
        .not([false], t('AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED'))
        .default(authorizePaymentInstallments)
        .required(t('AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED')),
    });
  };

  type AccountInformationSchemaType = yup.InferType<ReturnType<typeof makeSchema>>;

  return (
    <Modal open={isSetUpPaymentInstallmentsModalOpen} title={t('SET_UP_PAYMENT_INSTALLMENTS')} onClose={onClose}>
      <Form schema={makeSchema()} onSubmit={handleAccountInformationSubmit}>
        <ModalBody>
          <div className={classes.modalBody}>
            <Flex>
              <SectionV2 title={t('ACCOUNT_INFORMATION')} type={SectionTypes.H3} className="mt-0">
                <span>{t('SET_UP_PAYMENT_INSTALLMENTS_DESCRIPTION')}</span>
              </SectionV2>
              <Row>
                <Col xs={12} sm={10}>
                  <TextField name={`accountHolderName`} label={t('ACCOUNT_HOLDER_NAME')} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={5}>
                  <TextField
                    format={TextFieldFormats.DECIMAL_DIGITS}
                    name={`accountNumber`}
                    label={t('ACCOUNT_NUMBER')}
                  />
                </Col>
                <Col xs={12} sm={5}>
                  <TextField
                    format={TextFieldFormats.DECIMAL_DIGITS}
                    name={`confirmAccountNumber`}
                    label={t('CONFIRM_ACCOUNT_NUMBER')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={5}>
                  <TextField
                    format={TextFieldFormats.DECIMAL_DIGITS}
                    name={`abaRoutingNumber`}
                    label={t('ABA_ROUTING_NUMBER')}
                  />
                </Col>
                <Col xs={12} sm={5}>
                  <TextField
                    format={TextFieldFormats.DECIMAL_DIGITS}
                    name={`confirmAbaRoutingNumber`}
                    label={t('CONFIRM_ABA_ROUTING_NUMBER')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className={classes.checkboxWrapper}>
                  <Checkbox name="authorizePaymentInstallments" type="reverse">
                    <span>
                      {t('PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION', undefined, {
                        installmentAmount: pfQuote?.installmentAmount
                          ? toIntlFormatHelper(pfQuote?.installmentAmount, currencyMap[locale], locale)
                          : '',
                        company: pfQuote?.company || '',
                        frequency: t(pfQuote?.installmentFrequency || ''),
                      } as any)}
                    </span>
                  </Checkbox>
                </Col>
              </Row>
            </Flex>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" className="ml-100" submit>
            {t('SUBMIT')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
