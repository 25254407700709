import { Card, Icon } from '@appliedsystems/applied-design-system';
import { toIntlFormat, TransactionType } from '@appliedsystems/payments-core';
import React from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { AvailableTransactionTypes, PfInfo } from '../HostedPaymentPageContainer/types';
import classes from './PaymentOptionCard.module.scss';

type PaymentOptionCardProps = {
  title: string;
  subtotal: number;
  total: number;
  fee: number;
  showFeeAsPercentage: boolean;
  icon: string;
  isActiveCard: boolean;
  tooltip?: string;
  isFeeDetermined?: boolean;
  paymentOption?: AvailableTransactionTypes;
  pfInfo?: PfInfo;
  choosePaymentMethod: () => void;
};

export const PaymentOptionCard = ({
  icon,
  title,
  subtotal,
  total,
  fee,
  showFeeAsPercentage,
  isActiveCard,
  tooltip,
  isFeeDetermined,
  paymentOption,
  pfInfo,
  choosePaymentMethod,
}: PaymentOptionCardProps) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];

  const feeAsPercentage = ((fee / (subtotal || 1.0)) * 100).toFixed(1);

  const getIntlFormattedAmount = (amount: number) =>
    toIntlFormat(
      {
        amount,
        currencyCode,
      },
      locale,
    );

  return (
    <Card className={`${classes.card} ${isActiveCard ? classes.active : ''}`}>
      <section onClick={choosePaymentMethod}>
        <div className={classes.title}>
          <img src={icon} alt={title} />
          <span>{title}</span>
          {tooltip && (
            <Icon
              icon="ICircleIcon"
              size={18}
              tooltip={{
                content: tooltip,
                trigger: 'touchstart click mouseenter',
              }}
              className={classes.paymentMethodTooltip}
            />
          )}
        </div>
        <div className={classes.cardItems}>
          <div className={classes.cardItem}>
            <span className={paymentOption === TransactionType.PREMIUM_FINANCE ? 'w-100p' : ''}>{t('SUBTOTAL')}</span>
            <span>{getIntlFormattedAmount(subtotal)}</span>
          </div>
          {paymentOption === TransactionType.PREMIUM_FINANCE && pfInfo?.quote?.quoteId ? (
            <>
              <div className={classes.cardItem}>
                <span className="w-100p">
                  {pfInfo.quote.numberOfInstallments} {t('MONTHLY_PAYMENTS')}
                </span>
                <span>{pfInfo.quote.installmentAmount && getIntlFormattedAmount(pfInfo.quote.installmentAmount)}</span>
              </div>
              <div className={classes.cardItemBold}>
                <span className="w-100p">{t('EST_DOWN_PAYMENT')}</span>
                <span>{pfInfo.quote.downPaymentAmount && getIntlFormattedAmount(pfInfo.quote.downPaymentAmount)}</span>
              </div>
            </>
          ) : (
            <>
              <div className={classes.cardItem}>
                <span>{t('CONVENIENCE_FEE')}</span>
                {isFeeDetermined === false ? (
                  <span>TBD</span>
                ) : (
                  <span>{!showFeeAsPercentage ? `${getIntlFormattedAmount(fee)}` : `${feeAsPercentage}%`}</span>
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </Card>
  );
};
